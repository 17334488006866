<template>
  <!-- 编辑布局按钮 -->
  <ColorTextBtn v-if="showBtn" type="primary" size="small" @click="linkToAddCustomFloor"> 编辑 </ColorTextBtn>
</template>

<script>
import { DISABLE_CUSTOM_FLOOR_LAYOUT } from '@/utils/constant'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    showBtn({ data }) {
      const { enable } = data
      return enable == DISABLE_CUSTOM_FLOOR_LAYOUT
    }
  },

  methods: {
    linkToAddCustomFloor() {
      const { id } = this.data
      this.$router.push({
        path: '/user/editCustomFloor',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style>
</style>